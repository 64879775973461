<i18n src="@i18n/drone/drone.th.yaml" locale="th" lang="yaml"></i18n>
<i18n locale="th" lang="yaml" >
page.title: "ยืนยันการปรับแก้ Drone"
enum.page.header: "ยืนยันการปรับแก้ Drone"
enum.page.header.register: "ยืนยันการลงทะเบียน Drone"
enum.page.header.change_register: "ยืนยันการเปลี่ยนชื่อเจ้าของ Drone"
enum.page.header.backup: "ยืนยันการปรับเป็นเครื่องสำรอง"
enum.page.header.decommission: "ยืนยันการปรับเป็นเครื่องเสื่อมสภาพ"

page.description : "แสดงรายละเอียด Drone รอปรับแก้ ที่รอการยืนยันจากทาง HGR"

request.approve.label : "ยืนยัน"
request.reject.label : "ปฏิเสธ"

request.reject.register.success: 'ปฏิเสธการยื่นขอลงทะเบียน Drone เรียบร้อย'
request.reject.change_register.success: 'ปฏิเสธการยื่นขอเปลี่ยนชื่อเจ้าของ Drone เรียบร้อย'
request.reject.backup.success: 'ปฏิเสธการยื่นขอปรับเป็นเครื่องสำรองเรียบร้อย'
request.reject.decommission.success: 'ปฏิเสธการยื่นขอปรับเป็นเครื่องเสื่อมสภาพเรียบร้อย'

request.approve.register.success: 'ยืนยันการยื่นขอลงทะเบียน Drone เรียบร้อย'
request.approve.change_register.success: 'ยืนยันการยื่นขอเปลี่ยนชื่อเจ้าของ Drone เรียบร้อย'
request.approve.backup.success: 'ยืนยันการยื่นขอปรับเป็นเครื่องสำรองเรียบร้อย'
request.approve.decommission.success: 'ยืนยันการยื่นขอปรับเป็นเครื่องเสื่อมสภาพเรียบร้อย'

request.approve.confirm.title: 'ยืนยันคำขอ'
request.reject.confirm.title: 'ปฏิเสธคำขอ'

request.approve.confirm.message: 'คุณต้องการยืนยันคำขอนี้หรือไม่ ?'
request.reject.confirm.message: 'คุณต้องการปฏิเสธคำขอนี้หรือไม่ ?'

</i18n>

<template>
	<div class="page page-padding page-fit-mobile">
		<my-page-header :title="$tenum('page.header', changeType)" :description="$t('page.description')" />
		<a-card :bordered="false" class="mycard mycard-with-details drone-card">
			<div class="mycard-content">
				<template v-if="changeType === 'register'">
					<DroneRegisterDetail :change-request-data="changeRequestData" />
				</template>
				<template v-if="changeType === 'change_register'">
					<DroneChangeRegisterDetail :change-request-data="changeRequestData" />
				</template>
				<template v-if="changeType === 'backup'">
					<DroneBackupDetail :change-request-data="changeRequestData" />
				</template>
				<template v-if="changeType === 'decommission'">
					<DroneDecommissionDetail :change-request-data="changeRequestData" />
				</template>
				<div>
					<ul class="mycard-details">
						<li>
							<span class="label">ศูนย์บริการที่ยื่นเรื่องเข้ามา</span>
							<span class="value">{{ changeRequestData.request_by.company.name }}</span>
						</li>
						<li>
							<span class="label">ผู้ยื่นเรื่อง</span>
							<span class="value">{{ changeRequestData.request_by.username }}</span>
						</li>
					</ul>
				</div>
				<div class="action-panel">
					<a-button icon="left" size="large" :style="{ 'margin-right': '4px' }" :loading="loading" @click="goBack">
						{{ $t('common.back') }}
					</a-button>
					<a-button type="danger" class="btn-submit" icon="file-add" size="large" :loading="loading" @click="rejectRequest">
						{{ $t('request.reject.label') }}
					</a-button>
					<a-button type="info" class="btn-submit" icon="file-add" size="large" :loading="loading" @click="approveRequest">
						{{ $t('request.approve.label') }}
					</a-button>
				</div>
			</div>
		</a-card>
	</div>
</template>

<script>
import axios from 'axios'
import PageMixinVue from '@mixins/PageMixin.vue'
import ApiError from '@utils/errors/ApiError'
import DroneRegisterDetail from '@components/change_request/DroneRegisterDetail.vue'
import DroneChangeRegisterDetail from '@components/change_request/DroneChangeRegisterDetail.vue'
import DroneBackupDetail from '@components/change_request/DroneBackupDetail.vue'
import DroneDecommissionDetail from '@components/change_request/DroneDecommissionDetail.vue'
import { isVarEmpty } from '@/src/utils/stringUtil'

export default {
	page() {
		return {
			title: this.$t('page.title'),
		}
	},
	components: {
		DroneRegisterDetail,
		DroneChangeRegisterDetail,
		DroneBackupDetail,
		DroneDecommissionDetail,
	},
	mixins: [PageMixinVue],
	data() {
		return {
			changeRequestData: { warranty_start_date: null, request_by: { company: { name: null } } },
			loading: false,

			currentDroneStatus: '',
			newDroneStatus: '',
		}
	},
	computed: {
		paramRequestIdValue() {
			if (this.$notEmpty(this.$route.params.request_id)) {
				return this.$route.params.request_id
			} else {
				return undefined
			}
		},
		changeType() {
			if (isVarEmpty(this.currentDroneStatus) || isVarEmpty(this.newDroneStatus)) return ''
			if (this.currentDroneStatus === this.newDroneStatus) return 'change_register'
			switch (this.newDroneStatus) {
				case 'active':
					return 'register'
				case 'decommission':
					return 'decommission'
				case 'backup':
					return 'backup'
				default:
					return ''
			}
		},
	},
	mounted() {
		this.addBreadcrumbParams({
			droneSerialNo: '' + this.paramRequestIdValue,
		})
		this.fetchData()
	},
	methods: {
		goBack() {
			this.$open({ name: 'drone_change_request' })
		},
		fetchData() {
			this.loading = true
			axios
				.get(`/api/change-request/${this.paramRequestIdValue}`)
				.then((response) => {
					const data = response.data.data
					this.changeRequestData = data.changeRequest

					this.currentDroneStatus = this.changeRequestData.drone.status
					this.newDroneStatus = this.changeRequestData.drone_status
				})
				.catch((error) => {
					this.$message.error(ApiError.getDisplayErrorMessage(this, error))
				})
				.finally(() => {
					this.loading = false
				})
		},
		approveRequest() {
			this.$confirm({
				title: this.$t('request.approve.confirm.title'),
				content: this.$t('request.approve.confirm.message'),
				okText: this.$t('common.confirm'),
				maskClosable: true,
				onOk: () => {
					this.loading = true
					axios
						.post(`/api/change-request/${this.paramRequestIdValue}/approve`)
						.then((response) => {
							this.$message.success(this.$t(`request.approve.${this.changeType}.success`))
							this.$open({ name: 'drone_change_request' })
						})
						.catch((error) => {
							this.$message.error(ApiError.getDisplayErrorMessage(this, error))
						})
						.finally(() => {
							this.loading = false
						})
				},
			})
		},
		rejectRequest() {
			this.$confirm({
				title: this.$t('request.reject.confirm.title'),
				content: this.$t('request.reject.confirm.message'),
				okText: this.$t('common.confirm'),
				maskClosable: true,
				onOk: () => {
					this.loading = true
					axios
						.post(`/api/change-request/${this.paramRequestIdValue}/reject`)
						.then((response) => {
							this.$message.success(this.$t(`request.reject.${this.changeType}.success`))
							this.$open({ name: 'drone_change_request' })
						})
						.catch((error) => {
							this.$message.error(ApiError.getDisplayErrorMessage(this, error))
						})
						.finally(() => {
							this.loading = false
						})
				},
			})
		},
	},
}
</script>

<style lang="less" scoped>
.mycard {
	display: flex;
	justify-content: center;
}
.action-panel {
	margin-top: 20px;
}
.mycard-with-details ul.mycard-details > li .label {
	min-width: 100px;
}
</style>
