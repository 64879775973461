<i18n src="@i18n/drone/drone.th.yaml" locale="th" lang="yaml"></i18n>
<i18n locale="th" lang="yaml">
field.reason: "สาเหตุการเปลี่ยน"

</i18n>
<template>
	<div>
		<ul class="mycard-details">
			<li>
				<span class="label">{{ $t('drone.field.customer') }}</span>
				<span class="value" style="text-align: left">
					<CustomerLiteCard class="drone-owner-subcard" :customer="changeRequestData.customer" :bordered="true" />
				</span>
			</li>
			<li>
				<span class="label">{{ $t('drone.field.latest_flight_time_sec') }}</span>
				<span class="value"><FlightTimeView :time-seconds="changeRequestData.flight_duration_sec" /></span>
			</li>
			<li>
				<span class="label">{{ $t('field.reason') }}</span>
				<span class="value">{{ changeRequestData.request_status_reason }}</span>
			</li>
		</ul>
	</div>
</template>
<script>
import CustomerLiteCard from '../customer/CustomerLiteCard.vue'
import FlightTimeView from '../common/FlightTimeView.vue'
export default {
	components: { CustomerLiteCard, FlightTimeView },
	props: {
		changeRequestData: {
			type: null,
			default: () => [],
		},
	},
}
</script>